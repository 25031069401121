import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes as scheduleBuilderActionTypes } from 'actions/scheduleBuilder';
import { actionTypes as sessionActionTypes } from 'actions/session';

import {
  INITIAL_EMPTY_SCHEDULE_DRAWER_STATE,
  INITIAL_PRINT_DRAWER_STATE,
  INITIAL_PUBLISH_SCHEDULE_DRAWER_STATE,
  INITIAL_SCHEDULE_BUILDER_STATE,
  INITIAL_SYNC_SHIFTS_DRAWER_STATE,
} from 'features/scheduleBuilder/constants';

const general = (state = INITIAL_SCHEDULE_BUILDER_STATE, action) => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_SESSION:
      return state.merge(action.payload.scheduleBuilder);
    case scheduleBuilderActionTypes.UPDATE_CURRENT_TEMPLATE:
      return state.merge({
        currentTemplateId: action.payload.id,
      });
    case scheduleBuilderActionTypes.CREATE_FROM_TEMPLATE_SUCCESS: {
      if (action.meta.templateId) {
        return state.merge({
          currentTemplateId: action.templateId,
        });
      }

      return state;
    }

    default:
      return state;
  }
};

const shiftsSyncDrawer = (state = INITIAL_SYNC_SHIFTS_DRAWER_STATE, action) => {
  switch (action.type) {
    case scheduleBuilderActionTypes.TOGGLE_SYNC_SHIFTS_DRAWER:
      return state.merge({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        errorsCount: action.payload.errorsCount,
      });
    default:
      return state;
  }
};

const publishDrawer = (
  state = INITIAL_PUBLISH_SCHEDULE_DRAWER_STATE,
  action
) => {
  switch (action.type) {
    case scheduleBuilderActionTypes.TOGGLE_PUBLISH_DRAWER:
      return state.merge({
        data: action.payload.data,
        schedulePublished: action.payload.published,
      });

    case scheduleBuilderActionTypes.PUBLISH_SCHEDULE_SUCCESS:
      return state.merge({
        template: action.payload.template,
        templateRecurrence: action.payload.template_recurrence,
        templateIsChanged: action.payload.template_was_changed,
      });

    case scheduleBuilderActionTypes.SHOW_AFTER_PUBLISH_DRAWER:
      return state.merge({
        schedulePublished: true,
      });

    default:
      return state;
  }
};

const emptyScheduleDrawer = (
  state = INITIAL_EMPTY_SCHEDULE_DRAWER_STATE,
  action
) => {
  const removeTemplate = templateId => {
    const templates = state.get('templates');
    const index = templates.findIndex(
      template => template.get('id') === templateId
    );
    return index > -1 ? templates.delete(index) : templates;
  };

  const addTemplate = template => state.get('templates').push(fromJS(template));

  const updateTemplate = ({ id, name, updated_at, updated_by_name }) => {
    const templates = state.get('templates');
    const index = templates.findIndex(template => template.get('id') === id);
    if (index > -1) {
      return templates.set(
        index,
        templates.get(index).merge({
          name,
          updated_at,
          updated_by_name,
        })
      );
    }
    return templates;
  };

  switch (action.type) {
    case scheduleBuilderActionTypes.TOGGLE_EMPTY_SCHEDULE_DRAWER: {
      const newState = {
        date: action.payload.date,
        lastPublishedSchedule: action.payload.lastPublishedScheduleDate,
      };

      if (typeof action.payload.hasBeenClosed === 'boolean') {
        newState.hasBeenClosed = action.payload.hasBeenClosed;
      }

      return state.merge(newState);
    }

    case scheduleBuilderActionTypes.FETCH_TEMPLATES_REQUEST:
      return state.merge({ templatesIsFetching: true });

    case scheduleBuilderActionTypes.FETCH_TEMPLATES_SUCCESS:
      return state.merge({
        templates: fromJS(action.payload),
        templatesIsFetching: false,
      });

    case scheduleBuilderActionTypes.RENAME_TEMPLATE_SUCCESS:
      return state.merge({
        templates: updateTemplate(action.payload),
      });

    case scheduleBuilderActionTypes.DELETE_TEMPLATE_SUCCESS:
      return state.merge({
        templates: removeTemplate(action.meta.templateId),
      });

    case scheduleBuilderActionTypes.CREATE_FROM_TEMPLATE_SUCCESS: {
      if (action.meta.createEvent) {
        return state.merge({
          templates: addTemplate(action.payload),
        });
      }

      return state;
    }

    default:
      return state;
  }
};

const printDrawer = (state = INITIAL_PRINT_DRAWER_STATE, action) => {
  switch (action.type) {
    case scheduleBuilderActionTypes.TOGGLE_PRINT_DRAWER:
      return state.merge({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        viewType: action.payload.viewType,
      });

    default:
      return state;
  }
};

export default combineReducers({
  general,
  publishDrawer,
  emptyScheduleDrawer,
  printDrawer,
  shiftsSyncDrawer,
});
